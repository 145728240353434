@font-face {
  font-family: 'family-extra-bold';
  src: url('/fonts/poppins/Poppins-ExtraBold.ttf');
}
@font-face {
  font-family: 'family-bold';
  src: url('/fonts/poppins/Poppins-Bold.ttf');
}
@font-face {
  font-family: 'family-semi-bold';
  src: url('/fonts/poppins/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: 'family-regular';
  src: url('/fonts/poppins/Poppins-Medium.ttf');
}
@font-face {
  font-family: 'family-light';
  src: url('/fonts/poppins/Poppins-Light.ttf');
}
@font-face {
  font-family: 'family-thin';
  src: url('/fonts/poppins/Poppins-Thin.ttf');
}
